// src/pages/logout.js
import React, { useEffect } from "react";
import { navigate } from "gatsby";

const Logout = () => {
  useEffect(() => {
    localStorage.removeItem("token");
    navigate("/login");
  }, []);

  return (
    <div className="container mx-auto">
      <h1 className="text-4xl text-center my-8">Logging out...</h1>
    </div>
  );
};

export default Logout;
